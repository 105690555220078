import React from "react";
import { LinkContainer } from "react-router-bootstrap";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavItem from "react-bootstrap/NavItem";

export default () =>
<Navbar
bg="dark"
variant="dark"
expand="lg"
fixed="top"
fluid
collapseOnSelect
>
<Navbar.Brand href="#">
  {/* <Link to="/"></Link> */}
  Brew Mates Brewery
</Navbar.Brand>
<Navbar.Toggle aria-controls="basic-navbar-nav" />
<Navbar.Collapse
  id="basic-navbar-nav"
  className="justify-content-end"
>
  <Nav className="mr-auto">
    <LinkContainer to="/">
      <NavItem>Home</NavItem>
    </LinkContainer>
    {/* <LinkContainer to="/ontap/">
      <NavItem>On Tap</NavItem>
    </LinkContainer> */}
  </Nav>
</Navbar.Collapse>
</Navbar>;
